import React from 'react';
import styled from 'styled-components';
import Navigation from "../components/Navigation";
import Layout from "../components/Layout";

import theme from "../styles";
import {Helmet} from "react-helmet";

const Blog = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Blog | Diana Ignatescu</title>
      <link rel="canonical" href="https://dianaignatescu.com/blog" />
    </Helmet>
    <Navigation />
    <BlogHeader>
      <h2>Nothing to see here yet. Check back soon...</h2>
    </BlogHeader>
    <span />
  </Layout>
);

export default Blog;

const BlogHeader = styled.div`
  width: 800px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 100%;
    padding: 0 2.5rem;
  }

  h2 {
    color: ${theme.colors.lightYellow};
    line-height: normal;
  }
`;
